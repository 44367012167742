import "./contactMe.css";
//Libraries
import { Container, Row, Col } from "react-bootstrap";
//componetnts

//assets
function ContactMe() {
  return (
    <div className="contact_me" id="contact_me">
      <Container>
        <h1>Get in touch</h1>
        <p>
          Feel free to contact me regarding a project, my inbox is always open.
          Whether you have a question or just want to have a virtual coffee,
          I'll get back to you as soon as possible!
        </p>

        <Row>
          <Col>
            <div>
              <a
                href="mailto: mailhedri@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                <span>
                  <i className="bi bi-envelope"></i> Email Me
                </span>
                <div className="wave_animation"></div>
              </a>
            </div>
          </Col>
          <Col>
            <div>
              <a
                href="//api.whatsapp.com/send?phone=+393473697191&text=Hi, I visited your site and would like to get in touch!"
                target="_blank"
                rel="noreferrer"
              >
                <span>
                  <i class="bi bi-whatsapp"></i> WhatsApp
                </span>
                <div className="wave_animation"></div>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div >
  );
}

export default ContactMe;
