import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

function Footer() {
  const [ currentYear, setCurrentYear ] = useState("2022");
  useEffect(() => {
    const d = new Date();
    let year = d.getFullYear();
    if (currentYear !== year) {
      setCurrentYear(year);
    }
  }, []);
  return (

    <footer className="footer">
      <ul className="menu">
        <li className="menu__item"><a className="menu__link" href="#">Home</a></li>
        <li className="menu__item"><a className="menu__link" href="#about_me">About</a></li>
        <li className="menu__item"><a className="menu__link" href="#toolbox">Toolbox</a></li>
        {/* <li className="menu__item"><a className="menu__link" href="#">Projects</a></li> */}
        <li className="menu__item"><a className="menu__link" href="#contact_me">Contact</a></li>
      </ul>
      <p>&copy;{currentYear} Hedri Nel | All Rights Reserved</p>
    </footer>

  );
}

export default Footer;
