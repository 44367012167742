//Libraries
import React, { useEffect, useRef, useState } from "react";
// styling
//componetnts

//assets

function SectionScrollJump(props) {
  const ref = useRef(null);
  const [ isVisible, setIsVisible ] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const top = ref.current.getBoundingClientRect().top;
      setIsVisible(top >= window.innerHeight * 0.3);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (

    <div ref={ref} style={{ opacity: isVisible ? 1 : 0 }}>


      <a
        href={props.text}
        id="arrow-container"
        className={`arrow-container ${isVisible ? " show" : " hidden"}`}
      >
        <div className="arrow"></div>
        <div className="arrow"></div>
        <div className="arrow"></div>
      </a>

    </div>
  );
}

export default SectionScrollJump;
