import React, { useEffect, useState } from 'react';


function WaveComponent() {
  const [ isFadedOut, setIsFadedOut ] = useState(false);
  const [ prevScrollPos, setPrevScrollPos ] = useState(window.pageYOffset);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const shouldFadeOut = currentScrollPos > prevScrollPos;

      setIsFadedOut(shouldFadeOut);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [ prevScrollPos ]);


  return (
    <div id="wave_component" className={`fade-component ${isFadedOut ? 'fade-out' : ''}`}>
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 200"
          id="wave_1"
        >
          <path
            fill="#7db9b3"
            fillOpacity="0.5"
            d="M0,96L120,112C240,128,480,160,720,170.7C960,181,1200,171,1320,165.3L1440,160L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
          ></path>
        </svg>
      </div>

      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 10 2420 290"
          id="wave_2"
        >
          <path
            fill="#7db9b3"
            fillOpacity="0.5"
            d="M0,32L120,53.3C240,75,480,117,720,122.7C960,128,1200,96,1320,80L1440,64L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
          ></path>
        </svg>
      </div>
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 20 2440 210"
          id="wave_3"
        >
          <path
            fill="#7db9b3"
            fillOpacity="0.3"
            d="M0,224L120,186.7C240,149,480,75,720,53.3C960,32,1200,64,1320,80L1440,96L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
          ></path>
        </svg>
      </div>
    </div>
  );
}
export default WaveComponent;
