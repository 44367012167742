import React from "react";

import "./sidelinks.css";

import { Container, Button } from "react-bootstrap";

function SideLinks() {
  let redirectLinkedIn = () => {
    window.open("https://it.linkedin.com/in/hedrinel");
  };
  let redirectTwitter = () => {
    window.open("https://twitter.com/he3nel");
  };
  let redirectGithub = () => {
    window.open("https://github.com/FlyingVespa");
  };
  return (
    <div className="side_link_bar">
      <Container>
        <Button className="side_link_button" onClick={redirectLinkedIn}>
          <i className="bi bi-linkedin"></i>
        </Button>
        <hr />
        <Button className="side_link_button" onClick={redirectGithub}>
          <i className="bi bi-github"></i>
        </Button>
        <hr />
        <Button className="side_link_button" onClick={redirectTwitter}>
          <i className="bi bi-twitter"></i>
        </Button>
      </Container>
    </div>
  );
}

export default SideLinks;
