//Libraries
import React, { Fragment } from "react";
import ReactRotatingText from "react-rotating-text";
import { Row, Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
// styling
import "./home.css";
//componetnts
import SectionScrollJump from "../SectionScrollJump";
// import TopScrollJump from "../TopScrollJump";
//assets

function Home(props) {
  var portfolio = document.getElementById("portfolio");
  return (
    <>
      <div className="home" id="home">
        <Container>
          <Row>
            <ReactRotatingText id="rotating-text" items={["Ciao,", "Hello,", "Hi,", "Hoi,"]} />

            <h1>I am Hedri</h1>

            <h5>
              A Fullstack Developer, I build things for the web. I specialize in developing Websites and Web
              Applications that contribute to the overall success of the product.
            </h5>

            {/* to be added when porfolio active */}
            {/* <button onClick={() => portfolio.scrollIntoView()}> */}
            <button onClick={() => portfolio.scrollIntoView()}>
              <span>PORTFOLIO</span>
              <div className="wave"></div>
            </button>
          </Row>
        </Container>
        <SectionScrollJump text="#about_me" />
      </div>
    </>
  );
}

export default Home;
