import React, { useEffect, useState } from "react";
import {
  Container,
  Col,
  Row,
  Image,
  Button,
  Badge,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { AnimationOnScroll } from "react-animation-on-scroll";

function Project(props) {
  const [fadeInDirection, setFadeInDirection] = useState();
  const [fadeOutDirection, setFadeOutDirection] = useState();
  const [fadeInDirectionAlt, setFadeInDirectionAlt] = useState();
  const [fadeOutDirectionAlt, setFadeOutDirectionAlt] = useState();

  const checkProjectNumber = (projectNum) => {
    if (projectNum % 2 !== 0) {
      setFadeInDirection("animate__fadeInLeft");
      setFadeOutDirection("animate__fadeOutLeft");
    } else {
      setFadeInDirection("animate__fadeInRight");
      setFadeOutDirection("animate_fadeOutRight");
    }
    if (projectNum % 2 === 0) {
      setFadeInDirectionAlt("animate__fadeInLeft");
      setFadeOutDirectionAlt("animate__fadeOutLeft");
    } else {
      setFadeInDirectionAlt("animate__fadeInRight");
      setFadeOutDirectionAlt("animate_fadeOutRight");
    }
  };

  useEffect(() => {
    checkProjectNumber(props.id);
  }, []);

  return (
    <div
      key={props.id}
      className={"noteworthy_project"}
      id={"noteworthy_project"}
    >
      <Container>
        <Row>
          <Col md={12} lg={6}>
            <AnimationOnScroll
              animateIn={fadeInDirectionAlt}
              // animateOut={fadeOutDirectionAlt}
            >
              <Image
                src={props.img}
                alt={"project_" + props.project + "_image"}
                className="project_image"
              />
            </AnimationOnScroll>
          </Col>
          <Col md={12} lg={6}>
            <AnimationOnScroll animateIn={fadeInDirection}>
              <Row className="project_heading">
                <Col>
                  <h3>{props.project}</h3>
                </Col>
                <Col>
                  <Row>
                    <OverlayTrigger overlay={<Tooltip>Github Repo</Tooltip>}>
                      <Button
                        className="side_link_button"
                        href={props.repo}
                        target="_blank"
                      >
                        <i className="bi bi-github"></i>
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip>View Project</Tooltip>}>
                      <Button
                        className="side_link_button"
                        href={props.url}
                        target="_blank"
                      >
                        <i class="bi bi-window"></i>{" "}
                      </Button>
                    </OverlayTrigger>
                  </Row>
                </Col>
              </Row>
              <hr />
              <p>{props.desc}</p>
              <hr />

              <Row>
                <Col xs={12} lg={6}>
                  {props.features.map((f, i) => (
                    <Badge key={i}>{f}</Badge>
                  ))}
                </Col>
              </Row>
            </AnimationOnScroll>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Project;
