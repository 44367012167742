// styling
import "./portfolio.css";
import "animate.css/animate.min.css";
//Libraries
import { Container, Button } from "react-bootstrap";

//components
import Project from "./Project";
import OtherProjects from "./OtherProjects";
//assets
import datalist from "../../assets/projects/projects.json";

function Portfolio() {
  const dataLists = datalist;
  return (
    <div className="portfolio">
      <Container id="portfolio">
        <h1>Some Things I’ve Built</h1>

        {datalist.map((data, i) => (
          <Project
            props={data}
            key={i}
            project={data.project_title}
            img={data.img}
            desc={data.desc}
            features={data.features}
            url={data.url}
            repo={data.git}
          />
        ))}
      </Container>
      {/* <Container>
        <h2>Other Noteworthy Projects</h2>
        <h6> View the Archive</h6>
        <div>
          <OtherProjects />
        </div> 
        <Button>Show More</Button>
      </Container> */}
    </div>
  );
}

export default Portfolio;
