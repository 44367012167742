// styling
//Libraries
import React, { useEffect, useState, useLayoutEffect } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
//componetnts
import { Button } from "react-bootstrap";
import { FaArrowAltCircleUp } from "react-icons/fa";

//assets
function TopScrollJump() {
  const [ isHeaderMoved, setIsHeaderMoved ] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    const handleScroll = () => {
      setIsHeaderMoved(window.scrollY > 400);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [ isHeaderMoved ]);

  return (
    <div className="animate__fadeIn">
      <a
        className={`scroll_top_container ${isHeaderMoved ? "visible" : "hidden"}`}
        onClick={scrollToTop}
      >
        <Button id="scroll_top_btn"><i className="bi bi-arrow-bar-up"></i></Button>
      </a>
    </div>
  );
}
export default TopScrollJump;
