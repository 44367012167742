import React from "react";
import { Image } from "react-bootstrap";
import "../App.css";

import logoLoader from "../assets/HN.png"
const Preloader = () => {
    return (

        <div className={`preloader-container`}>
            <Image src={logoLoader} className="preloader-animation" />
        </div>
    );
};

export default Preloader;