//Libraries
import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { AnimationOnScroll } from "react-animation-on-scroll";
//styling
import "./toolbox.css";
//componetnts
import SectionScrollJump from "../SectionScrollJump";
//assets
import {
  FaHtml5,
  FaJsSquare,
  FaGitSquare,
  FaReact,
  FaNodeJs,
  FaBootstrap,
} from "react-icons/fa";
import { SiCss3, SiMongodb, SiFirebase, SiFigma } from "react-icons/si";
import { AiFillApi } from "react-icons/ai";


function Toolbox() {
  const rowRef = useRef();
  const [ isVisible, setIsVisible ] = useState(false);

  const handleScroll = () => {
    const top = rowRef.current.getBoundingClientRect().top;
    const bottom = rowRef.current.getBoundingClientRect().bottom;
    const windowHeight = window.innerHeight;

    if (top < windowHeight && bottom >= 0) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let toolbox = [
    { number: 1, title: "Html", image: <FaHtml5 /> },
    { number: 2, title: "JavaScript", image: <FaJsSquare /> },
    { number: 3, title: "CSS", image: <SiCss3 /> },
    { number: 4, title: "React", image: <FaReact /> },
    { number: 5, title: "MongoDB", image: <SiMongodb /> },
    { number: 6, title: "Firebase", image: <SiFirebase /> },
    { number: 7, title: "Node.js", image: <FaNodeJs /> },
    { number: 8, title: "Git", image: <FaGitSquare /> },
    { number: 9, title: "Bootstrap", image: <FaBootstrap /> },
    { number: 10, title: "API's", image: <AiFillApi /> },
    { number: 11, title: "Figma", image: <SiFigma /> },
  ];

  return (
    <>
      <div className="toolbox" id="toolbox">
        <Container>
          <h1>What I can do</h1>
          <Row>
            <AnimationOnScroll animateIn="animate__fadeInDown">
              <Row>
                <p>
                  As a full-stack developer, I design, build, and maintain
                  websites and online apps. Although I prefer the front-end (as
                  seen by the aesthetic components), I can also develop a
                  complentary backend (server-side, data processing, storage,
                  etc.)
                </p>
                <p>Here's a list of technologies I commonly use</p>
              </Row>
            </AnimationOnScroll>
            <Row ref={rowRef}>
              {toolbox.map((toolbox, i) => (
                <Col
                  xs={4}
                  md={2}
                  key={i}
                  className={`column ${isVisible ? " visible" : "fade-out"}`}
                  style={{ transitionDelay: `${i * 0.1}s` }}
                >
                  <Row>{toolbox.image}</Row>
                  <Row>{toolbox.title}</Row>
                </Col>
              ))}
            </Row>
          </Row>
        </Container>
        <SectionScrollJump text="#contact_me" />
      </div>
    </>
  );
}

export default Toolbox;
