//stylings
import "./aboutMe.css";
//Libraries
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { AnimationOnScroll } from "react-animation-on-scroll";
//componetnts
import SectionScrollJump from "../SectionScrollJump";
//assets
import Donkey from "../../assets/donkey.jpg";
import Ice from "../../assets/ice.jpg";
import Bench from "../../assets/bench.jpg";
import Photo from "../../assets/photo.jpg";
import Hanging from "../../assets/hanging.jpg";
import Parachute from "../../assets/parachute.jpg";
const AboutMe = () => {
  const [ currentImageIndex, setCurrentImageIndex ] = useState(0);
  const [ expanded, setExpanded ] = useState(false);

  const images = [ Donkey, Bench, Ice, Hanging, Parachute, Photo ];

  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const descriptionText = ` Greetings! I'm thrilled to introduce myself as Hedri, an
  adventurous spirit from South Africa who has found a new
  home in the captivating land of Sicily. Having spent
  considerable time on the high seas, I yearned for a place
  to anchor myself and call it home. Italy beckoned me, and
  I fearlessly embraced the challenge of not only adapting
  to a new country and language but also reinventing my
  career path. Driven by an unwavering belief that no
  challenge is too daunting, I sought an endeavor that would
  fuel both my intellect and creativity. Thus, I embarked on
  a self-study voyage, which evolved into immersive boot
  camps, ultimately propelling me to become a proficient
  full-stack developer. However, my professional history is
  as diverse as my interests. I've dabbled in architectural
  drafting, indulged my artistic flair through jewelry
  design, excelled in the art of salesmanship, and even
  curated unforgettable travel experiences as a travel
  agent. With this colorful background, I bring a myriad of
  skills to the table, capable of illuminating future
  projects from various angles. So, if you're seeking a
  dynamic professional who thrives on challenges, embraces
  diverse experiences, and possesses a natural flair for
  creative problem-solving, then I'm your ideal
  collaborator. Let's embark on an exciting journey together
  and create something truly extraordinary. And of course,
  let's enjoy a cup of coffee while we're at it!    `

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(
        (currentIndex) => (currentIndex + 1) % images.length
      );
    }, 10000);
    return () => clearInterval(interval);
  }, []);
  var contactme = document.getElementById("contact_me");
  const mobileView = (
    <div className="mobile-only">
      <p>{expanded ? descriptionText : `${descriptionText.slice(0, 350)}...`}</p>
      <button onClick={toggleExpand}>{expanded ? '<< Read Less' : 'Read More >>'}</button>
    </div>
  );

  const desktopView = <p>{descriptionText}</p>;
  return (
    <>
      <div className="about_me" id="about_me">
        <Container>
          <h1 className="animate__animated animate__fadeIn">About Me</h1>
          <Row>
            <Row >
              <AnimationOnScroll animateIn="animate__fadeInLeft">
                <div>
                  <Image src={images[ currentImageIndex ]} />
                </div>
              </AnimationOnScroll>
            </Row>
            <Row>
              <AnimationOnScroll animateIn="animate__fadeInRight">
                <div>
                  <Row>
                    <div>
                      {mobileView}
                      <div className="desktop-only">{desktopView}</div>
                    </div>
                    <button onClick={() => contactme.scrollIntoView()}>
                      <span>Want to grab a coffee ☕?</span>
                      <div className="wave"></div>
                    </button>
                  </Row>
                </div>
              </AnimationOnScroll>
            </Row>
          </Row>
        </Container>
        <SectionScrollJump text="#toolbox" />
      </div>
    </>
  );
};

export default AboutMe;
