import React, { useEffect } from "react";
import {
  Badge,
  Col,
  Card,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { BsFolder, BsBoxArrowUpRight, BsCodeSlash } from "react-icons/bs";

function OtherProjects() {
  let redirectGithub = () => {
    window.open("https://github.com/FlyingVespa");
  };
  const projectFolders = [
    {
      _id: "1",
      text: "test1",
      delay: 0,
      tools: ["React", "Html", "JavaScript"],
    },
    {
      _id: "2",
      text: "test1",
      delay: 100,
      tools: ["React", "Html", "JavaScript"],
    },
    {
      _id: "3",
      text: "test1",
      delay: 200,
      tools: ["React", "Html", "JavaScript"],
    },
    {
      _id: "4",
      text: "test1",
      delay: 300,
      tools: ["React", "Html", "JavaScript", "MongoDb"],
    },
    {
      _id: "5",
      text: "test1",
      delay: 400,
      tools: ["React", "Html", "JavaScript", "Next"],
    },
    {
      _id: "6",
      text: "test1",
      delay: 500,
      tools: ["React", "Html", "JavaScript", "Css"],
    },
    {
      _id: "7",
      text: "test1",
      delay: 600,
      tools: ["React", "Html", "JavaScript", "Css"],
    },
    {
      _id: "8",
      title: "",
      text: "test1",
      delay: 700,
      tools: ["React", "Html", "JavaScript"],
    },
  ];
  return (
    <>
      {projectFolders.map((projectFolder) => (
        <AnimationOnScroll
          animateIn="animate__fadeInDown"
          id={projectFolder.id}
          delay={projectFolder.delay}
        >
          <Card className="other_projects">
            <Card.Header>
              <BsFolder id="BsFolder" />
              <Col>
                <OverlayTrigger overlay={<Tooltip>Github Repo</Tooltip>}>
                  <Button className="side_link_button" onClick={redirectGithub}>
                    <BsCodeSlash />
                  </Button>
                </OverlayTrigger>

                <OverlayTrigger overlay={<Tooltip>View Project</Tooltip>}>
                  <Button>
                    <BsBoxArrowUpRight />
                  </Button>
                </OverlayTrigger>
              </Col>
            </Card.Header>
            <Card.Body>
              <Card.Title>Project 1</Card.Title>
              <Card.Text>
                <span>lorem ipsum lorem ipsum lorem ipsum lorem </span>
                <span style={{ color: "blue" }}>Read More </span>
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              {projectFolder.tools.map((item) => (
                <Badge>{item}</Badge>
              ))}
            </Card.Footer>
          </Card>
        </AnimationOnScroll>
      ))}
    </>
  );
}

export default OtherProjects;
