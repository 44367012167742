//library
import React, { useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { Container, Navbar, Image, Nav } from "react-bootstrap";
// components
import { Turn as Hamburger } from "hamburger-react";
import WaveComponent from "../WaveComponent";

// stylings
import "./navbar.css";
// assets
import logo from "../../assets/HN.png";
import SideLinks from "../SideLinks/SideLinks";

export default function NavBar() {
  const [ isOpen, setOpen ] = React.useState(false);
  const handleToggle = () => {
    setOpen(!isOpen);
  };

  const [ isMobile, setIsMobileView ] = React.useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {isMobile ? (
        <>
          <navbar className="navbar">
            <div href="#home">
              <Image id="logo" src={logo} />
              <span>Hedri Nel</span>
            </div>
            <div className="menu-btn-container">
              {" "}
              <Hamburger
                toggled={isOpen}
                toggle={setOpen}
                color="#FFF"
                rounded
              />
            </div>
          </navbar>
          {isOpen ? (
            <div className="dropdown-container open">
              <div>
                <Nav.Link href="#home" onClick={handleToggle}>Home</Nav.Link >
                <Nav.Link href="#about_me" onClick={handleToggle}>About</Nav.Link>
                <Nav.Link href="#toolbox" onClick={handleToggle}>Toolbox</Nav.Link>
                {/* <Nav>Portfolio</Nav> */}
                <Nav.Link href="#contact_me" onClick={handleToggle}>Contact</Nav.Link>
              </div>
              <div>
                <SideLinks />
              </div>
            </div>
          ) : (
            <div className="dropdown-container closed"></div>
          )}
        </>
      ) : (<>
        <navbar className="navbar">
          <div href="#home">
            <Image id="logo" src={logo} />
            <span>Hedri Nel</span>
          </div>
          <div>
            {/* <Nav.Link href="#">Home</Nav.Link> */}
            <Nav.Link href="#about_me">About</Nav.Link>
            <Nav.Link href="#toolbox">Toolbox</Nav.Link>
            {/* <Nav>Portfolio</Nav> */}
            <Nav.Link href="#contact_me">Contact</Nav.Link>
          </div>
        </navbar>
        <SideLinks />
      </>
      )}
      <WaveComponent id="wave_component" />

    </>
  );
}
