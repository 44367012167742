// ! libraries
import React from "react";
import { useEffect, useState } from "react";
// ! styling
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// ! components
import WaveComponent from "./components/WaveComponent";
import Navbar from "./components/Navbar/Navbar.jsx";
// import ComingSoon from "./components/ComingSoon";
import Home from "./components/Home/Home";
import TopScrollJump from "./components/TopScrollJump";
import SideLinks from "./components/SideLinks/SideLinks";
import AboutMe from "./components/AboutMe/AboutMe";
import Toolbox from "./components/Toolbox/Toolbox";
import Portfolio from "./components/Portfolio/Portfolio";
import ContactMe from "./components/ContactMe/ContactMe";
import Footer from "./components/Footer";
import Preloader from "./components/Preloader";
// ! assets

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 4500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className="App">
          <Navbar />
          <Home />
          {/* <TopScrollJump /> */}
          <AboutMe />
          <Toolbox />
          <Portfolio />
          <ContactMe />
          {/* <SideLinks /> */}
          <Footer />
        </div>
      )}
    </>
  );
};

export default App;
